<template>
  <div>
    <div class="contents">
      <div class="tableSearch">
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="90px">
             <el-form-item label="群名称/联系人" style="margin-bottom:10px">
              <el-input v-model="searchForm.name" clearable  style="width:200px"
                placeholder="请输入群名称/联系人"></el-input>
            </el-form-item>
            <el-form-item label="消息类型" style="margin-bottom:10px;">
              <el-select v-model="searchForm.messageType" clearable  placeholder="全部" style="width:200px">
                <el-option label="文本" value="0"></el-option>
                <el-option label="文件" value="1"></el-option>
                <el-option label="分享" value="2"></el-option>
                <el-option label="图片" value="3"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="消息发送状态" style="margin-bottom:10px;">
              <el-select v-model="searchForm.sendStatus" clearable  placeholder="全部" style="width:200px">
                <el-option label="待发送" value="0"></el-option>
                <el-option label="发送中" value="1"></el-option>
                <el-option label="已发送" value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Excel文件" style="margin-bottom:10px;">
              <el-select v-model="searchForm.resultCode" clearable  placeholder="全部" style="width:200px">
                <el-option label="收单异常" value="1"></el-option>
                <el-option label="物流异常" value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="消息内容" style="margin-bottom:10px">
              <el-input v-model="searchForm.remark" clearable  style="width:200px"
                placeholder="请输入文件名称/消息内容"></el-input>
            </el-form-item>

            <el-form-item label="发送时间" style="margin-bottom:10px">
              <el-date-picker v-model="searchForm.date" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>

            <el-button @click="getTopSearch" type="primary" icon="el-icon-search">查询</el-button>
            <el-button @click="() => searchForm={}">重置</el-button>
          </el-form>
      </div>

    <div class="mainbox">
       <el-table
        :data="tableData"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          type="index" width="40" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          prop="name"
          label="群名称/联系人"
          width="120">
        </el-table-column>
         <el-table-column
          prop="type"
          label="消息类型"
          width="50" header-align="center" align="center">
           <template slot-scope="scope">
            {{scope.row.type==0?'群聊':'私信'}}
          </template>
        </el-table-column>
         <el-table-column
          label="发送人"
          width="80" header-align="center" align="center">
          <template slot-scope="scope">
            {{scope.row.msgSender?scope.row.msgSender.name:''}}
          </template>
        </el-table-column>
         <el-table-column
          label="接收人"
          width="80" header-align="center" align="center">
          <template slot-scope="scope">
            {{scope.row.msgReceiver?scope.row.msgReceiver.name:'无'}}
          </template>
        </el-table-column>
         <el-table-column
          prop="remark"
          label="消息内容" >
          <template slot-scope="scope">
            <el-badge :is-dot="scope.row.resultCode!=0 && scope.row.manualMarked==0" class="item">
              <div v-if="scope.row.messageType!=1" style="white-space: pre-wrap;" class="text-wrapper">{{scope.row.remark}}</div>
              <a :href="scope.row.message.filePath" target="abank">{{scope.row.message.fileName}}</a>
            </el-badge>
          </template>
        </el-table-column>         
        <el-table-column
          label="消息类型"
          width="90">
           <template slot-scope="scope">
            【{{shopArr[scope.row.messageType]}}】
          </template>
        </el-table-column>

        <el-table-column
          prop="remark"
          label="备注"
          width="90">
          <template slot-scope="scope">
            <div v-if="scope.row.messageType==1" style="white-space: pre-wrap;" class="text-wrapper">{{scope.row.remark}}</div>
          </template>
        </el-table-column>

         <el-table-column
          prop="createTime"
          width="160"
          align="center"
          label="消息创建时间">
        </el-table-column>

        <el-table-column
          prop="updateTime"
          align="center"
          width="160"
          label="消息发送时间">
        </el-table-column>

       <el-table-column
          label="发送状态"
          width="80"
          align="center"
          >
           <template slot-scope="scope">
              <el-tag :type="tagArr[scope.row.sendStatus]">{{sendArr[scope.row.sendStatus]}} </el-tag>
          </template>
        </el-table-column>
        
        <el-table-column
          label="操作"
          width="80"
          align="center"
          >
           <template slot-scope="scope">
              <div v-if="scope.row.resultCode!=0 && scope.row.manualMarked==0">
                <el-badge is-dot class="item">
                  <el-button type="text" size="small" @click="mark(scope.row)" >已处理</el-button>
                </el-badge>
              </div>

              <div v-if="scope.row.sendStatus!=2">
                  <el-button type="text" size="small" @click="send(scope.row)" >重新发送</el-button>
              </div>
          </template>
        </el-table-column>

      </el-table>
      <div class="paginations">
          <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          @current-change="changePage" :current-page="page" :page-size="pageSize"
          :page-sizes="[20, 40, 50, 100]"
          :total="total">
        </el-pagination>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { robotMessage , repush, mark } from "@/api/robot";
let moment = require('moment');
export default {
  name: 'WeixinMessage',
  data() {
    return {
      searchForm: {
        name: '',
        sendStatus: '',
        messageType: '',
        sendStatus: '',
        date: '',
        remark: ''
      },
      total: 0,
      page: 1,
      pageSize: 20,
      shopflag: false,
      shopArr: ['文本', '文件', '分享', '图片','小程序'],
      sendArr: ['待发送', '发送中','已发送'],
      tagArr: ['info', 'danger', 'success'],
      loadingFile: false,
      activeName: 'first',
      tableData: [],
      multipleSelection: []
    }
  },
  methods: {
    mark(row) {
      mark(row).then(res => {
        if(res.code === 200) {
          this.tableData.forEach(item => {
            if(item.id == row.id) {
              item.manualMarked = res.data.manualMarked
            }
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    send(row) {
      const that = this
      const msg = row.messageType == 1 ? '重新发送可能会导致订单重复推送，是否确认？' : '是否确认要重新发送？'
      this.$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          repush(row).then(res => {
            if(res.code === 200) {
              this.$message.success(res.message)
              this.getTopSearch()
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {
        });
    },
    handleClose(){
      this.shopflag = false;
    },
    getTopSearch(e){
      var that = this
      var datas = that.searchForm
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.startTime = that.searchForm.date ? moment(that.searchForm.date[0]).format('YYYY-MM-DD 00:00:00') : ''
      datas.endTime = that.searchForm.date ? moment(that.searchForm.date[1]).format('YYYY-MM-DD 23:59:59') : ''
      datas.orderByCreated = "DESC"
      robotMessage(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.tableData = res.data.rows
          that.total = res.data.totalRows
        }else{
          this.$message.error(res.message)
        }
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    editFun(row){
      var that = this
      that.$router.push({
        path: "/channel/detail?id=" + row.id,
      })
    },
    delChannel(row){
       var that = this
       var datas = {
         id: row.id,
       }
       deleteGroups(datas).then(res => {
         if(res.code === 200) {
            that.$message({
              message: '删除成功',
              type: 'success'
            })
            that.getTopSearch()
         }else{
           this.$message.error(res.message)
         }
       })
    },
    shouquan(){
      var that = this
      that.$router.push({
        path: "/channel/detail"
      })
    }
  },
  mounted(){
    this.getTopSearch()
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}

.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.classObj{
  float:right;
  font-size: 12px;
  color: #999;
  cursor: pointer;
}
.shopLogo{
  display: block;
  overflow: hidden;
}
.shopLogo ol{
  float:left;
  width: 100px;
  border: 1px solid #eee;
  padding: 5px;
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
}
.shopLogo ol img{
  width: 100%;display: block;
}
.item {
  margin-top: 5px;
  margin-right: 5px;
}
</style>